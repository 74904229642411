import axios from 'axios'

const baseURL = process.env.VUE_APP_SERVER_URL + '/api/notifications'

export default {
  namespaced: true,
  state: {
    count_unread: 0,
    is_notification_loading: false,
    notifications: [],
    unread_notifications: [],
    notification_pagination: {
      current_page: 1,
      total: 0,
      last_page: 0
    }
  },
  mutations: {
    setCountUnread (state, count) {
      state.count_unread = count
    },
    setUnreadNotifications (state, v) {
      state.unread_notifications = v
    },
    setNotifications (state, v) {
      state.notifications = v
    },
    setReadNotification (state, id) {
      const index = state.notifications.findIndex(item => item.id === id)
      if (index !== -1) {
        state.notifications[index].read_at = 1
        state.notifications = [...state.notifications]
      }
      const indexUnread = state.unread_notifications.findIndex(item => item.id === id)
      if (indexUnread !== -1) {
        state.unread_notifications[indexUnread].read_at = 1
        state.unread_notifications = [...state.unread_notifications]
      }
    },
    setNotificationPage (state, page) {
      state.notification_pagination.current_page = page
    },
    setNotificationPagination (state, payload) {
      state.notification_pagination.current_page = payload.current_page ? payload.current_page : 1
      state.notification_pagination.total = payload.total ? payload.total : 0
      state.notification_pagination.last_page = payload.last_page ? payload.last_page : 0
    }
  },
  actions: {
    getUnread ({
      state,
      commit
    }) {
      const promise = axios.get(baseURL + '/list')
      promise.then(response => {
        commit('setUnreadNotifications', response.data.data)
        commit('setCountUnread', response.data.count)
      })
    },
    getCountUnread ({
      state,
      commit
    }) {
      const promise = axios.get(baseURL + '/count')
      promise.then(response => {
        commit('setCountUnread', response.data.count)
      })
    },
    getNotifications ({
      state,
      commit
    }, filters) {
      state.is_notification_loading = true
      const promise = axios.get(baseURL, {
        params: filters
      })
      promise.then(response => {
        commit('setNotifications', response.data.data)
        commit('setCountUnread', response.data.count)
        commit('setNotificationPagination', response.data.meta)
        state.is_notification_loading = false
      })
    },
    markRead ({
      state,
      commit
    }, id) {
      const promise = axios.post(baseURL + '/' + id)
      promise.then(response => {
        commit('setReadNotification', id)
        commit('setCountUnread', state.count_unread - 1)
      })
    },
    markAllRead ({
      state,
      commit
    }) {
      return axios.post(baseURL + '/read-all')
    }
  }
}
