export const price = {
  methods: {
    formatPrice (amount, currency = 'USD') {
      if (!amount) {
        amount = 0
      }

      if (!currency) {
        return '-'
      }

      return amount.toLocaleString('en-EN', {
        style: 'currency',
        currency: currency
      })
      // return c.replace('грн', '').replace('₴', '')
    },
    transactionTotal (transaction) {
      const amount = transaction.amount
      const rolling = (transaction.rolling && !transaction.rolling.return_rollings_count) ? transaction.rolling.amount : 0
      const commission = transaction.commission ? transaction.commission.amount : 0

      return this.formatPrice((amount - rolling - commission) / 100)
    }
  }
}
