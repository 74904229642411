import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#003a7f', // colors.blue.accent4, // #E53935
        secondary: colors.orange, // #FFCDD2
        accent: colors.indigo.base, // #3F51B5
        anchor: colors.blue.accent4
      }
    }
  }
})
