<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        width="380"
        persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            class="mt-2"
            text
            color="orange"
            dark
            v-bind="attrs"
            v-on="on"
        >
          Forgot password?
        </v-btn>
      </template>

      <v-card
          :loading="isLoading"
          :disabled="isLoading"
      >
        <v-toolbar color="black" dark>
          <v-toolbar-title>Password recovery</v-toolbar-title>
          <v-spacer/>
          <v-btn icon @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text v-if="step === 1" class="mt-4">
          <p>Enter your email with which you registered your account</p>
          <v-text-field
              v-model="requestData.email"
              label="Email"
              type="email"
              outlined
              :error="hasError('email')"
              :error-messages="getError('email')"
          />
        </v-card-text>
        <v-card-text v-if="step === 2" class="mt-4">
          <p>A confirmation code for changing your password has been sent to your email. Enter this code in the field and create a new password</p>
          <v-otp-input
              v-model="requestData.code"
              length="6"
              type="number"
              :error="hasError('code')"
              :error-messages="getError('code')"
          ></v-otp-input>
          <v-divider class="mt-2 mb-4"/>
          <v-text-field
              outlined
              v-model="requestData.password"
              :label="$t('login.new_password')"
              name="password"
              :type="password_field_type ? 'password' : 'text'"
              :error="hasError('password')"
              :error-messages="getError('password')"
              :append-icon="password_field_type ? 'visibility' : 'visibility_off'"
              @click:append="password_field_type = !password_field_type"
              @input="setError('password')"
          ></v-text-field>
          <v-text-field
              outlined
              v-model="requestData.password_confirmation"
              :label="$t('login.password_confirmation')"
              name="password"
              :type="password_field_type ? 'password' : 'text'"
              :error="hasError('password_confirmation')"
              :error-messages="getError('password_confirmation')"
              :append-icon="password_field_type ? 'visibility' : 'visibility_off'"
              @click:append="password_field_type = !password_field_type"
              @input="setError('password_confirmation')"
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <LoginSupportComponent/>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              @click="step === 1 ? sendRecovery() : sendRecoveryConfirm()"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { eventBus } from '@/main'
import LoginSupportComponent from '@/components/Support/LoginSupportComponent.vue'

export default {
  name: 'ForgetPasswordComponent',
  components: { LoginSupportComponent },
  data () {
    return {
      dialog: false,
      isLoading: false,
      password_field_type: true,
      step: 1,
      requestData: {
        email: null,
        code: null,
        password: null,
        password_confirmation: null
      }
    }
  },
  watch: {
    dialog (v) {
      if (!v) {
        this.step = 1
        this.requestData = {
          email: null,
          code: null,
          password: null,
          password_confirmation: null
        }
      }
    }
  },
  computed: {
    ...mapGetters('errors', ['hasError', 'getError'])
  },
  methods: {
    ...mapMutations('errors', ['setError']),
    ...mapActions('auth', ['getRecovery', 'confirmRecovery']),
    sendRecovery () {
      this.isLoading = true
      const promise = this.getRecovery(this.requestData)
      promise.then(r => {
        this.isLoading = false
        this.step = 2
      }).catch(e => {
        this.isLoading = false
      })
    },
    sendRecoveryConfirm () {
      this.isLoading = true
      const promise = this.confirmRecovery(this.requestData)
      promise.then(r => {
        this.isLoading = false
        this.dialog = false
        eventBus.$root.$emit(
          'send_notify',
          'success',
          'Your password has been successfully changed!',
          'Now you can try to log in using the new password'
        )
      }).catch(e => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
