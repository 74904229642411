<script>
import { mapActions } from 'vuex'

export default {
  name: 'NotificationDetailsModal',
  props: ['notification'],
  data () {
    return {
      show_dialog: false
    }
  },
  methods: {
    ...mapActions('notifications', ['markRead'])
  },
  watch: {
    notification: function (v) {
      this.show_dialog = !!v
      if (v && !v.read_at) {
        this.markRead(v.id)
      }
    },
    show_dialog: function (v) {
      if (!v) {
        this.$emit('clear')
      }
    }
  }
}
</script>

<template>
  <v-dialog
    v-model="show_dialog"
    max-width="500"
  >
    <v-card>
      <v-card-title v-if="notification">{{ notification.data.title }}</v-card-title>
      <v-card-text v-if="notification" v-html="notification.data.html_message"></v-card-text>
      <v-card-actions v-if="notification && notification.data.url && notification.data.url !== $route.fullPath">
        <v-btn text color="primary" :to="notification.data.url">Go to source</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">

</style>
