import axios from 'axios'

const baseURL = process.env.VUE_APP_SERVER_URL + '/api/chat-branches'

export default {
  namespaced: true,
  state: {
    is_load: false,
    chat_branches: []
  },
  mutations: {
    setChatBranches (state, payload) {
      state.chat_branches = payload
    },
    setChatBranch (state, payload) {
      state.chat_branch = payload
    }
  },
  actions: {
    async getChatBranches ({
      state,
      commit
    }) {
      state.is_load = true
      const promise = axios.get(baseURL)

      promise.then(response => {
        commit('setChatBranches', response.data.data)
        state.is_load = false
      })

      return promise
    }
  }
}
