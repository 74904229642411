import axios from 'axios'

const baseURL = process.env.VUE_APP_SERVER_URL

export default {
  namespaced: true,
  state: {
    is_load: false,
    pay_system: null,
    pay_systems: []
  },
  mutations: {
    setPaySystem (state, payload) {
      state.pay_system = payload
    },
    setPaySystems (state, payload) {
      state.pay_systems = payload
    }
  },
  actions: {
    async getPaySystems ({ state, commit }, filters) {
      state.is_load = true
      const promise = axios.get(baseURL + '/api/pay-systems', { params: filters })
      promise.then(response => {
        commit('setPaySystems', response.data.data)
        state.is_load = false
      }).catch(e => {
        state.is_load = false
      })

      return promise
    }
  }
}
