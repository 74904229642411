<template>
  <div v-if="user">
    <v-app-bar
      app
      color="grey.darken4"
      clipped-left
      clipped-right
      dark
      elevation="8"
    >
      <v-app-bar-nav-icon @click="show_main_menu = !show_main_menu">
<!--        <v-badge-->
<!--          :content="count_unread"-->
<!--          :value="count_unread"-->
<!--          dot-->
<!--          left-->
<!--          color="red"-->
<!--        >-->
          <v-icon>menu</v-icon>
<!--        </v-badge>-->
      </v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <NotificationsComponent/>
    </v-app-bar>
    <MainMenuAsideComponent :is_show="show_main_menu" @show-main-menu="setShowMenu"/>
    <ActionAsideComponent
      :is_show="show_action_aside"
      @show-action-menu="e => show_action_aside = e"
    />
    <v-main class="mb-14">
      <transition name="fade-transition" mode="out-in">
        <router-view/>
      </transition>
    </v-main>
    <v-fab-transition>
      <v-btn
        v-if="showNeedActions"
        v-show="!show_action_aside"
        color="black"
        dark
        fixed
        bottom
        right
        fab
        @click="show_action_aside = true"
      >
        <v-badge
          :content="actions ? actions.length : 0"
          :value="actions ? actions.length : 0"
          color="red"
        >
          <v-icon>mdi-alert</v-icon>
        </v-badge>
      </v-btn>
    </v-fab-transition>
    <v-dialog
      v-model="show_get_start_window"
      max-width="400"
    >
      <v-card>
        <v-toolbar flat>
          <v-icon left>mdi-comment-question-outline</v-icon>
          <v-toolbar-title>Get Started: Integration Guide</v-toolbar-title>
          <v-spacer/>
          <v-btn icon @click="show_get_start_window = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text>
          <div class="mt-4 mb-4">
            This guide provides all the necessary settings for integrating and activating your project with LSP.
            Follow the detailed instructions to ensure a smooth setup process.<br>
            <br>
            If you have any questions or require further assistance, please contact our specialists. We are here to
            support you at every step.
          </div>
          <v-btn block dark color="green" @click="goToHelp()">Go to Integration Guide</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
  <div v-else class="text-center" style="margin-top: 50vh;">
    <v-progress-circular
      :size="50"
      color="amber"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
import MainMenuAsideComponent from '@/components/asides/MainMenuAsideComponent'
import { translate } from '@/mixins/translate'
import { price } from '@/mixins/price'
import { ws } from '@/mixins/ws_notification'
import { mapActions, mapState } from 'vuex'
import ActionAsideComponent from '@/components/asides/ActionAsideComponent.vue'
import NotificationsComponent from '@/components/Notifications/NotificationsComponent.vue'

export default {
  name: 'DashboardMobileLayout',
  mixins: [translate, price, ws],
  components: {
    NotificationsComponent,
    ActionAsideComponent,
    MainMenuAsideComponent
  },
  data: () => ({
    show_main_menu: false,
    menu: true,
    show_aside: true,
    show_action_aside: false,
    show_get_start_window: false
  }),
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('actions', ['actions']),
    showNeedActions: {
      get () {
        if (this.$route.fullPath.includes('/dashboard/chats')) {
          return false
        }

        if (this.$route.fullPath.includes('/dashboard/projects/')) {
          return false
        }

        return true
      }
    }
  },
  methods: {
    ...mapActions('auth', ['getUser', 'logout']),
    ...mapActions('actions', ['getActionList']),
    goToHelp () {
      this.show_get_start_window = false
      this.$router.push('/dashboard/articles/3')
    },
    setShowMenu (v) {
      this.show_main_menu = v
    }
  },
  async created () {
    await this.getUser()
    const actionFilters = {}

    if (this.$route.fullPath.includes('/dashboard/projects/')) {
      actionFilters.project_id = this.$route.params.id
    }

    await this.getActionList(actionFilters)
    this.joinWS()

    this.$root.$on('show_action_request_aside', () => {
      this.show_action_aside = true
    })

    this.$root.$on('show_get_started', () => {
      this.show_get_start_window = true
    })
  }
}
</script>

<style scoped>

</style>
