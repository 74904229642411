import axios from 'axios'
import i18n from '@/i18n'
const baseURL = process.env.VUE_APP_SERVER_URL + '/api/users'

export default {
  namespaced: true,
  state: {
    lang: localStorage.getItem('system-locale') || 'en',
    lang_list: ['en']
  },
  mutations: {
    setLang (state, value) {
      // TODO validate lang
      // value = value === 'ru' ? 'ru' : 'ua'
      localStorage.setItem('system-locale', value)
      state.lang = value
      i18n.locale = value
      axios.defaults.headers.localization = value
    }
  },
  actions: {
    changeUserLang ({ commit, rootState }, lang) {
      axios.put(baseURL, {
        lang: lang
      }).then(res => {
      })
    }
  }
}
