import Vue from 'vue'
import Vuex from 'vuex'

import auth from '@/store/modules/auth'
import lang from '@/store/modules/lang'
import errors from '@/store/modules/errors'
import projects from '@/store/modules/projects'
import documents from '@/store/modules/documents'
import documentSign from '@/store/modules/documentSign'
import paySystems from '@/store/modules/paySystems'
import transactions from '@/store/modules/transactions'
import actions from '@/store/modules/actions'
import chat from '@/store/modules/chat'
import chatBranches from '@/store/modules/chatBranches'
import operations from '@/store/modules/operations'
import payouts from '@/store/modules/payouts'
import notifications from '@/store/modules/notifications'
import articles from '@/store/modules/articles'
import payLinks from '@/store/modules/payLinks'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    lang,
    errors,
    projects,
    documents,
    documentSign,
    paySystems,
    transactions,
    actions,
    chat,
    chatBranches,
    operations,
    payouts,
    notifications,
    articles,
    payLinks
  }
})
