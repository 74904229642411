import axios from 'axios'

const baseURL = process.env.VUE_APP_SERVER_URL

export default {
  namespaced: true,
  state: {
    is_load: false
  },
  mutations: {
  },
  actions: {
    async getSignForDocument ({ state, commit }, data) {
      state.is_load = true
      const promise = axios.get(baseURL + '/api/document-signs', { params: data })
      promise.then(response => {
        state.is_load = false
      }).catch(e => {
        state.is_load = false
      })

      return promise
    },
    async sign ({ state, commit }, data) {
      state.is_load = true
      const promise = axios.post(baseURL + '/api/document-signs', data)
      promise.then(response => {
        state.is_load = false
      }).catch(e => {
        state.is_load = false
      })

      return promise
    }
  }
}
