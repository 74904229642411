import axios from 'axios'

const baseURL = process.env.VUE_APP_SERVER_URL + '/api/articles'

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    async getArticlesList ({ state, commit }) {
      return axios.get(baseURL)
    },
    async getArticle ({ state, commit }, id) {
      return axios.get(baseURL + '/' + id)
    },
    async downloadFile ({ state, commit }, data) {
      state.is_load = true
      const promise = axios.get(baseURL + '/' + data.id + '/download/' + data.file_name, { responseType: 'blob' })
      promise.then(response => {
        state.is_load = false
        const blob = new Blob([response.data])
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = data.original_name
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(e => {
        state.is_load = false
      })

      return promise
    }
  }
}
