import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginPage from '@/views/Auth/LoginPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginPage,
    meta: { layout: 'base-layout' }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/Auth/RegisterPage'),
    meta: { layout: 'base-layout' }
  },
  {
    path: '/confirmation',
    name: 'confirmation',
    component: () => import('../views/Auth/CodeConfirmPage'),
    meta: { layout: 'base-layout' }
  },
  {
    path: '/dashboard/profile',
    name: 'profile',
    component: () => import('../views/User/ProfileView'),
    meta: { layout: 'dashboard-layout' }
  },
  {
    path: '/dashboard/balance',
    name: 'profile',
    component: () => import('../views/User/BalanceView.vue'),
    meta: { layout: 'dashboard-layout' }
  },
  {
    path: '/dashboard/balance/:date',
    name: 'profile',
    component: () => import('../views/User/OperationListView.vue'),
    meta: { layout: 'dashboard-layout' }
  },
  // Dashboard Projects
  {
    path: '/dashboard/projects',
    name: 'dashboard-projects',
    component: () => import('../views/Projects/ProjectListView'),
    meta: { layout: 'dashboard-layout' }
  },
  {
    path: '/dashboard/projects/:id/edit',
    name: 'dashboard-project-edit',
    component: () => import('../views/Projects/EditProjectView'),
    meta: { layout: 'dashboard-layout' }
  },
  {
    path: '/dashboard/projects/:id',
    name: 'project-view',
    component: () => import('../views/Projects/ProjectPage.vue'),
    meta: { layout: 'dashboard-layout' },
    children: [
      {
        path: 'general',
        name: 'project-general',
        component: () => import('../views/Projects/Components/GeneralProjectView.vue'),
        meta: { layout: 'dashboard-layout' }
      },
      {
        path: 'transactions',
        name: 'project-transactions',
        component: () => import('../views/Projects/Components/ProjectTransactionsView.vue'),
        meta: { layout: 'dashboard-layout' }
      },
      {
        path: 'balance',
        name: 'project-balance',
        component: () => import('../views/Projects/Components/ProjectBalance.vue'),
        meta: { layout: 'dashboard-layout' }
      },
      {
        path: 'payouts',
        name: 'project-payouts',
        component: () => import('../views/Projects/Components/ProjectPayoutsView.vue'),
        meta: { layout: 'dashboard-layout' }
      },
      {
        path: 'documents',
        name: 'project-documents',
        component: () => import('../views/Projects/Components/ProjectDocuments.vue'),
        meta: { layout: 'dashboard-layout' }
      },
      {
        path: 'pay-links',
        name: 'project-pay-links',
        component: () => import('../views/Projects/Components/ProjectPayLinksView.vue'),
        meta: { layout: 'dashboard-layout' }
      }
    ]
  },
  // Dashboard Transactions
  {
    path: '/dashboard/transactions',
    name: 'dashboard-transactions',
    component: () => import('../views/Transactions/TransactionListView'),
    meta: { layout: 'dashboard-layout' }
  },
  {
    path: '/dashboard/transactions/:id',
    name: 'dashboard-transactions',
    component: () => import('../views/Transactions/TransactionView.vue'),
    meta: { layout: 'dashboard-layout' }
  },
  // Chats
  {
    path: '/dashboard/chats',
    name: 'dashboard-chat',
    component: () => import('../views/Chats/ChatPage.vue'),
    meta: { layout: 'dashboard-layout' }
  },
  // Notifications
  {
    path: '/dashboard/notifications',
    name: 'dashboard-notifications',
    component: () => import('../views/Notifications/NotificationListPage.vue'),
    meta: { layout: 'dashboard-layout' }
  },
  {
    path: '/dashboard/articles',
    name: 'dashboard-articles',
    component: () => import('../views/Articles/ArticlesView.vue'),
    meta: { layout: 'dashboard-layout' }
  },
  {
    path: '/dashboard/articles/:id',
    name: 'dashboard-article',
    component: () => import('../views/Articles/ArticleView.vue'),
    meta: { layout: 'dashboard-layout' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
