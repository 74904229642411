import axios from 'axios'
import auth from '@/store/modules/auth'

const baseURL = process.env.VUE_APP_SERVER_URL + '/api/chats'
const messageURL = process.env.VUE_APP_SERVER_URL + '/api/chat-messages'

export default {
  namespaced: true,
  state: {
    chat_id: null,
    project_id: null,
    show_chat: false,
    chat_user: null,
    is_load: false,
    chats: [],
    chat: null,
    pagination: {
      current_page: 1,
      per_page: localStorage.getItem('helix_per_page') || 10
    }
  },
  mutations: {
    setChats (state, payload) {
      state.chats = payload
      state.chats.forEach(item => {
        item.label = ''

        if (item.chat_branch) {
          item.label += '' + item.chat_branch.name
        } else {
          item.users.forEach(u => {
            if (u.id !== auth.state.user && item.label === '') {
              item.label += '' + u.name
            }
          })
        }

        if (item.project) {
          item.label += ' | ' + item.project.name
        }
      })
    },
    setChat (state, payload) {
      state.chat = payload
    },
    setChatId (state, id) {
      state.chat_id = id
    },
    setProjectId (state, id) {
      state.project_id = id
    },
    setChatUser (state, payload) {
      state.chat_user = payload
    },
    setShowChat (state, payload) {
      state.show_chat = payload
    },
    setPagination (state, payload) {
      state.pagination = payload
    },
    setPage (state, value) {
      state.pagination.current_page = value
      if (value > state.pagination.last_page) {
        state.pagination.current_page = state.pagination.last_page
      }
      if (value < 1) {
        state.pagination.current_page = 1
      }
    },
    setReadMessages (state, id) {
      const index = state.chats.findIndex(item => item.id === id)
      if (index !== -1) {
        state.chats[index].chat_messages_count = 0
      }
    },
    setPerPage (state, value) {
      state.pagination.per_page = value
      localStorage.setItem('helix_per_page', value)
    }
  },
  actions: {
    async getChats ({
      state,
      commit
    }) {
      state.is_load = true
      const promise = axios.get(baseURL)

      promise.then(response => {
        commit('setChats', response.data.data)
        state.is_load = false
      })

      return promise
    },
    async getChat ({
      state,
      commit
    }, id) {
      state.is_load = true
      const promise = axios.get(baseURL + '/' + id)

      promise.then(response => {
        commit('setChat', response.data.data)
        state.is_load = false
      })

      return promise
    },
    async createChat ({
      state,
      commit
    }, data) {
      state.is_load = true
      const promise = axios.post(baseURL + '/', data)

      promise.then(response => {
        commit('setChat', response.data.data)
        state.is_load = false
      })

      return promise
    },
    async sendChatMessage ({
      state,
      commit
    }, data) {
      state.is_load = true
      const promise = axios.post(messageURL, data)

      promise.then(response => {
        state.is_load = false
      })

      return promise
    },
    async sendFile ({
      state,
      commit
    }, formData) {
      return axios.post(messageURL + '/files', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    },
    async updateChat ({
      state,
      commit
    }, data) {
      state.is_load = true
      const promise = axios.put(baseURL + '/' + data.id, data)

      promise.then(response => {
        commit('setChat', response.data.data)
        state.is_load = false
      })

      return promise
    },
    async deleteChat ({
      state,
      commit
    }, id) {
      state.is_load = true
      const promise = axios.delete(baseURL + '/' + id)

      promise.then(response => {
        state.is_load = false
      }).catch(e => {
        state.is_load = false
      })

      return promise
    }
  }
}
