import axios from 'axios'

const baseURL = process.env.VUE_APP_SERVER_URL

export default {
  namespaced: true,
  state: {
    is_load: false,
    actions: []
  },
  getters: {
    count_actions: state => {
      let count = 0
      state.actions.forEach(item => {
        if (!item.is_completed) {
          count++
        }
      })
      return count
    }
  },
  mutations: {
    setActions (state, payload) {
      state.actions = payload
    },
    addAction (state, payload) {
      state.actions.push(payload)
    }
  },
  actions: {
    async getActionList ({ state, commit }, filters) {
      state.is_load = true
      const promise = axios.get(baseURL + '/api/actions', {
        params: filters
      })
      promise.then(response => {
        state.is_load = false
        commit('setActions', response.data.data)
      }).catch(e => {
        state.is_load = false
      })

      return promise
    },
    async completed ({ state, commit }, id) {
      return axios.post(baseURL + '/api/actions/' + id + '/completed')
    }
  }
}
