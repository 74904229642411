<template>
  <v-container fluid class="set-font">
    <v-layout class="mt-16" align-center justify-center>
      <v-card elevation="12" width="360"
              :loading="is_load"
      >
        <v-toolbar
          dark
          flat
          color="primary"
        >
          <a style="width: 100%" class="mt-2 mb-2 text-center" href="/">
            <v-img width="160" style="margin: 20px auto" src="/images/lsp-logo.png"/>
          </a>
        </v-toolbar>
        <v-toolbar
          dark
          flat
        >
          <v-toolbar-title style="margin: auto">
            <b>Login to Dashboard</b>
          </v-toolbar-title>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text>
          <v-form
            class="mt-2"
            ref="form"
            lazy-validation
          >
            <v-text-field
              autofocus
              v-model="email"
              :label="$t('login.email')"
              name="email"
              type="email"
              autocomplete="username-1"
              :error="hasError('email')"
              :error-messages="getError('email')"
              @keyup.enter="sendLogin"
            ></v-text-field>
            <v-text-field
              v-model="password"
              :label="$t('login.password')"
              name="password"
              :type="password_field_type ? 'password' : 'text'"
              :error="hasError('password')"
              :error-messages="getError('password')"
              :append-icon="password_field_type ? 'visibility' : 'visibility_off'"
              @click:append="password_field_type = !password_field_type"
              @keyup.enter="sendLogin"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-text class="pt-0">
          <v-btn
            :loading="is_load"
            :disabled="is_load"
            color="primary"
            @click="sendLogin()"
            large
            width="100%"
          >
            <v-icon left>mdi-login</v-icon>
            {{ $t('login.sign_in') }}
          </v-btn>
          <ForgetPasswordComponent class="mt-2"/>
        </v-card-text>
        <v-divider/>
        <v-card-subtitle>
          {{ $t('login.no_account_register') }}
        </v-card-subtitle>
        <v-card-text class="pt-0 d-flex justify-space-between">
          <v-btn
            outlined
            dark
            color="orange"
            @click="goTo('/register')"
          >
            <v-icon left>mdi-account-plus-outline</v-icon>
            {{ $t('login.register') }}
          </v-btn>
          <login-support-component/>
        </v-card-text>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import { translate } from '@/mixins/translate'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import LoginSupportComponent from '@/components/Support/LoginSupportComponent.vue'
import ForgetPasswordComponent from '@/components/Auth/ForgetPasswordComponent.vue'

export default {
  name: 'LoginPage',
  components: {
    ForgetPasswordComponent,
    LoginSupportComponent
  },
  mixins: [translate],
  data () {
    return {
      email: null,
      password: null,
      password_field_type: true
    }
  },
  computed: {
    ...mapGetters('errors', ['hasError', 'getError']),
    ...mapState('auth', ['user', 'is_load'])
  },
  methods: {
    ...mapActions('auth', ['loginRequest']),
    ...mapMutations('auth', ['setAccessToken']),
    sendLogin () {
      const promise = this.loginRequest({
        email: this.email,
        password: this.password
      })

      promise.then(r => {
        this.$router.push('/confirmation')
      })
    },
    goTo (url) {
      this.$router.push({
        path: url,
        query: {
          ...this.$route.query
        }
      })
    }
  },
  created () {
    localStorage.setItem('showed-get-start', '')
    if (this.$route.query.tx) {
      this.setAccessToken(this.$route.query.tx)
      this.$router.push('/dashboard/projects')
    }
  }
}
</script>

<style scoped>

</style>
