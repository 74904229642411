<script>
import { mapActions, mapState } from 'vuex'
import NotificationDetailsModal from '@/components/Notifications/NotificationDetailsModal.vue'

export default {
  name: 'NotificationsComponent',
  components: { NotificationDetailsModal },
  data () {
    return {
      show_notifications: false,
      selected_index: null,
      selected_notification: null
    }
  },
  computed: {
    ...mapState('notifications', [
      'count_unread',
      'unread_notifications',
      'is_notification_loading'
    ])
  },
  watch: {
    $route: function (v) {
      this.show_notifications = false
    },
    show_notifications: function (v) {
      this.selected_index = null
    },
    selected_index: function (v) {
      if (v !== null) {
        const index = this.unread_notifications.findIndex(item => item.id === v)
        if (index !== -1) {
          this.selected_notification = this.unread_notifications[index]
        }
      }
    }
  },
  methods: {
    ...mapActions('notifications', ['getUnread']),
    clearSelected () {
      this.selected_notification = null
      this.selected_index = null
    }
  }
}
</script>

<template>
  <v-menu
    v-model="show_notifications"
    offset-y
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        @click="getUnread()"
        v-bind="attrs"
        v-on="on"
        small
        icon
        class="mr-4"
        fab
        depressed
      >
        <v-badge
          :content="count_unread"
          :value="count_unread"
          color="red"
          overlap
        >
          <v-icon>
            notifications
          </v-icon>
        </v-badge>
      </v-btn>
    </template>
    <v-card width="480">
      <v-list v-if="unread_notifications" max-height="500" style="overflow-y:auto">
        <v-list-item-group
          v-model="selected_index"
          active-class="pink--text"
        >
          <v-list-item :class="{'item-already-read' : item.read_at}" three-line v-for="item in unread_notifications" :key="item.id">
            <v-list-item-content @click="selected_index = item.id">
              <v-list-item-title>{{item.data.title}}</v-list-item-title>
              <v-list-item-subtitle>{{item.data.message}}</v-list-item-subtitle>
              <v-list-item-subtitle>{{ item.created_at | moment('DD/MM HH:mm') }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-card-text v-if="unread_notifications.length === 0">
        You have no new messages
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-list>
          <v-list-item to="/dashboard/notifications">
            <v-icon class="mr-2">
              mdi-bell-badge-outline
            </v-icon>
            <v-list-item-title>View all notifications</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card-actions>
    </v-card>
    <NotificationDetailsModal
      :notification="selected_notification"
      @clear="clearSelected()"
    />
  </v-menu>
</template>

<style scoped lang="scss">
.item-already-read {
  background: #eee;
}
</style>
