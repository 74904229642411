import axios from 'axios'

const baseURL = process.env.VUE_APP_SERVER_URL

export default {
  namespaced: true,
  state: {
    is_load: false,
    project: null,
    projects: [],
    pagination: {
      current_page: 1
    }
  },
  mutations: {
    setProject (state, payload) {
      state.project = payload
    },
    setProjects (state, payload) {
      state.projects = payload
    },
    setIsConfirmed (state, v) {
      state.project.is_confirmed = v
    },
    setPagination (state, payload) {
      state.pagination = payload
    },
    setPage (state, value) {
      state.pagination.current_page = value
      if (value > state.pagination.last_page) {
        state.pagination.current_page = state.pagination.last_page
      }
      if (value < 1) {
        state.pagination.current_page = 1
      }
    }
  },
  actions: {
    async getProjects ({ state, commit }) {
      state.is_load = true
      const promise = axios.get(baseURL + '/api/projects', {
        params: {
          page: state.pagination.current_page || 1
        }
      })
      promise.then(response => {
        commit('setProjects', response.data.data)
        commit('setPagination', response.data.meta)
        state.is_load = false
      }).catch(e => {
        state.is_load = false
      })

      return promise
    },
    async getProject ({ state, commit }, id) {
      state.is_load = true
      const promise = axios.get(baseURL + '/api/projects/' + id)
      promise.then(response => {
        commit('setProject', response.data.data)
        state.is_load = false
      }).catch(e => {
        state.is_load = false
      })

      return promise
    },
    async createProject ({ state, commit }, data) {
      state.is_load = true
      const promise = axios.post(baseURL + '/api/projects', data)
      promise.then(response => {
        commit('setProject', response.data.data)
        state.is_load = false
      }).catch(e => {
        state.is_load = false
      })

      return promise
    },
    async updateProject ({ state, commit }, data) {
      state.is_load = true
      const promise = axios.put(baseURL + '/api/projects/' + data.id + '/', data)
      promise.then(response => {
        commit('setProject', response.data.data)
        state.is_load = false
      }).catch(e => {
        state.is_load = false
      })

      return promise
    },
    async startVerification ({ state, commit }, data) {
      state.is_load = true
      const promise = axios.post(baseURL + '/api/projects/' + data.id + '/verification', data)
      promise.then(response => {
        state.is_load = false
      }).catch(e => {
        state.is_load = false
      })

      return promise
    },
    async getMiniProjectList () {
      return axios.get(baseURL + '/api/projects/list')
    },
    async chooseTariff ({ state, commit }, id) {
      const promise = axios.post(baseURL + '/api/projects/' + id + '/choose-tariff')
      promise.then(response => {
        commit('setProject', response.data.data)
        state.is_load = false
      }).catch(e => {
        state.is_load = false
      })

      return promise
    },
    async actionTariff ({ state, commit }, data) {
      const promise = axios.post(baseURL + '/api/projects/' + data.id + '/action-tariff', data)
      promise.then(response => {
        commit('setProject', response.data.data)
        state.is_load = false
      }).catch(e => {
        state.is_load = false
      })

      return promise
    },
    async copyProject ({ state, commit }, id) {
      return axios.post(baseURL + '/api/projects/' + id + '/copy')
    },
    async deleteProject ({ state, commit }, id) {
      return axios.delete(baseURL + '/api/projects/' + id)
    }
  }
}
