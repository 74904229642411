import { eventBus } from '@/main'

export default {
  namespaced: true,
  state: {
    message: '',
    errors: []
  },
  getters: {
    hasErrors: state => {
      return Object.keys(state.errors).length > 0
    },
    hasError: state => field => {
      return Object.prototype.hasOwnProperty.call(state.errors, [field])
    },
    getError: state => field => {
      if (Object.prototype.hasOwnProperty.call(state.errors, [field])) {
        return state.errors[field][0]
      }
      return null
    }
  },
  mutations: {
    setError (state, field) {
      delete state.errors[field]
    },
    setErrors (state, payload) {
      state.message = payload.message
      state.errors = payload.errors
      let errorDescription = ''

      if (!state.errors) {
        state.errors = []
      }

      for (const [key, value] of Object.entries(state.errors)) {
        console.log(`${key}: ${value}`)
        errorDescription += value[0] + '<br>'
      }

      eventBus.$root.$emit(
        'send_notify',
        'error',
        payload.message,
        errorDescription
      )
    },
    clearErrors (state) {
      state.message = ''
      state.errors = []
    },
    clearError (state, key) {
      if (Object.prototype.hasOwnProperty.call(state.errors, [key])) {
        delete state.errors[key]
      }
    }
  }
}
