<template>
  <v-navigation-drawer
      v-model="show_aside"
      temporary
      app
      @input="setShow"
      right
      width="480px"
  >
    <v-toolbar dark flat color="black">
      <v-btn icon @click="setShow(false)">
        <v-icon>close</v-icon>
      </v-btn>
      <v-toolbar-title>Action required</v-toolbar-title>
    </v-toolbar>
    <v-divider/>
    <div v-if="!is_loading">
    <v-card  class="mt-2 mx-2" v-for="item in actions" :key="'action-' + item.id">
      <v-card-text class="text-h6 pb-0" to="/">Project: <router-link class="green--text" :to="'/dashboard/projects/'+item.project.id+'/general'">{{item.project.name}}</router-link></v-card-text>
      <v-card-text v-html="item.message"></v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <span v-if="!item.is_completed">Task Completed?</span>
        <v-btn
          v-if="!item.is_completed"
          :disabled="user.is_blocked"
          @click="sendCompleted(item.id)"
          color="green ml-4"
          dark
        >Confirm</v-btn>
        <span v-else class="green--text ml-4">Confirmed</span>
      </v-card-actions>
    </v-card>
    </div>
    <v-skeleton-loader
      v-else
      type="article, article, actions"
    ></v-skeleton-loader>
    <v-card-text v-if="!is_loading && actions.length === 0">
      <v-alert
        dense
        text
        type="success"
      >
        You don't have any action requests yet
      </v-alert>
    </v-card-text>
  </v-navigation-drawer>
</template>

<script>
// import { mapActions, mapState } from 'vuex'
import { ws } from '@/mixins/ws_notification'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ActionAsideComponent',
  props: ['is_show'],
  mixins: [ws],
  data: () => ({
    show_aside: false,
    is_loading: false
  }),
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('actions', ['actions'])
  },
  watch: {
    is_show: {
      immediate: true,
      handler (v) {
        this.show_aside = v
        this.$emit('show-action-menu', v)
        if (v) {
          this.is_loading = true
          const actionFilters = {}
          if (this.$route.fullPath.includes('/dashboard/projects/')) {
            actionFilters.project_id = this.$route.params.id
          }
          this.getActionList(actionFilters).then(r => {
            this.is_loading = false
          }).catch(e => {
            this.is_loading = false
          })
        }
      }
    }
  },
  methods: {
    ...mapActions('actions', ['getActionList', 'completed']),
    sendCompleted (id) {
      const promise = this.completed(id)
      promise.then(r => {
        const actionFilters = {}
        if (this.$route.fullPath.includes('/dashboard/projects/')) {
          actionFilters.project_id = this.$route.params.id
        }
        this.getActionList(actionFilters)
      })
    },
    setShow (v) {
      this.$emit('show-action-menu', v)
    }
  },
  created () {
  }
}
</script>

<style scoped lang="scss">
.router-link-active {
  color: gray!important;
  text-decoration: none;
  cursor: text;
}
</style>
