import axios from 'axios'

const baseURL = process.env.VUE_APP_SERVER_URL

export default {
  namespaced: true,
  state: {
    is_load: false,
    document: null,
    documents: []
  },
  getters: {
    counter_not_sign: state => {
      let count = 0
      state.documents.forEach(item => {
        if (!item.document_signs_count) {
          count++
        }
      })

      return count
    }
  },
  mutations: {
    setDocument (state, payload) {
      state.document = payload
    },
    setDocuments (state, payload) {
      state.documents = payload
    }
  },
  actions: {
    async getDocuments ({ state, commit }, filters) {
      state.is_load = true
      const promise = axios.get(baseURL + '/api/documents', { params: filters })
      promise.then(response => {
        commit('setDocuments', response.data.data)
        state.is_load = false
      }).catch(e => {
        state.is_load = false
      })

      return promise
    },
    async downloadDocument ({ state, commit }, doc) {
      state.is_load = true
      const promise = axios.get(baseURL + '/api/documents/' + doc.id, { responseType: 'blob' })
      promise.then(response => {
        console.log(response)
        state.is_load = false
        const blob = new Blob([response.data])
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = doc.original_name + '.' + doc.extension
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(e => {
        state.is_load = false
      })

      return promise
    }
  }
}
