<template>
  <v-navigation-drawer
      class="aside-gradient"
      v-model="show_aside"
      app
      dark
      clipped
      @input="setShow"
      :src="'/images/new-bg.jpeg'"
  >
    <v-list
        nav
        dense
    >
      <div v-if="this.$vuetify.breakpoint.mobile">
        <v-list-item>
          <v-list-item-content>
            <div class="d-flex align-center justify-center">
              <v-img
                  alt="Logo"
                  class="shrink mr-2 ml-2"
                  contain
                  src="/images/lsp-logo.png"
                  transition="scale-transition"
                  width="160"
              />
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </div>
      <v-subheader>{{ $t('user.title') }}</v-subheader>
      <v-menu
          offset-x
          transition="scale-transition" class="mx-4"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-list-item
              v-bind="attrs"
              v-on="on"
          >
            <v-icon class="mr-2" dark>
              mdi-account-circle
            </v-icon>
            <v-list-item-title>{{ user.name }} <span class="red--text" v-if="user && user.is_blocked">Blocked!</span></v-list-item-title>
          </v-list-item>
        </template>
        <v-list>
          <v-list-item
              to="/dashboard/balance"
              dense
          >
            <v-list-item-title>Balance: {{formatPrice(user.balance / 100)}}</v-list-item-title>
          </v-list-item>
          <v-list-item
              to="/dashboard/profile"
              dense
          >
            <v-list-item-title>{{ $t('user.profile') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
              dense
              @click="logout()">
            <v-list-item-title>{{ $t('login.out') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-divider></v-divider>
      <v-subheader>Menu</v-subheader>
      <v-list-item-group
          active-class="blue--text text--lighten-3"
      >
        <v-list-item to="/dashboard/projects">
          <v-icon class="mr-2">list</v-icon>
          <v-list-item-title>{{ $t('projects.title') }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/dashboard/transactions">
          <v-icon class="mr-2">paid</v-icon>
          <v-list-item-title>{{ $t('transactions.title') }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/dashboard/chats">
          <v-icon class="mr-2">chat</v-icon>
          <v-list-item-title>Support</v-list-item-title>
        </v-list-item>
        <v-list-item to="/dashboard/articles">
          <v-icon class="mr-2">help</v-icon>
          <v-list-item-title>Help</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// import { mapActions, mapState } from 'vuex'
import { ws } from '@/mixins/ws_notification'
import { mapActions, mapState } from 'vuex'
import { price } from '@/mixins/price'

export default {
  name: 'MainMenuAsideComponent',
  props: ['is_show'],
  mixins: [ws, price],
  data: () => ({
    show_aside: false,
    show_join_telegram: false
  }),
  computed: {
    ...mapState('auth', ['user'])
  },
  watch: {
    is_show: {
      immediate: true,
      handler (v) {
        this.show_aside = v
        this.$emit('show-main-menu', v)
      }
    }
  },
  methods: {
    ...mapActions('auth', ['logout']),
    // ...mapActions('content', ['getAsideArticles']),
    setShow (v) {
      this.$emit('show-main-menu', v)
    }
  },
  created () {
  }
}
</script>

<style scoped lang="scss">

</style>
