<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          outlined
            color="red lighten-2"
            dark
            v-bind="attrs"
            v-on="on"
        >
          <v-icon left>mdi-face-agent</v-icon>
          Support
        </v-btn>
      </template>

      <v-card
          :loading="isLoading"
          :disabled="isLoading"
      >
        <v-toolbar color="black" dark>
          <v-toolbar-title>Support</v-toolbar-title>
          <v-spacer/>
          <v-btn icon @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="mt-4">
          <v-text-field
              v-model="requestData.email"
              label="Email"
              type="email"
              outlined
              :error="hasError('email')"
              :error-messages="getError('email')"
          />
          <v-text-field
              v-model="requestData.phone"
              label="Phone"
              outlined
              :error="hasError('phone')"
              :error-messages="getError('phone')"
          />
          <v-textarea
              v-model="requestData.message"
              label="Message"
              outlined
              :error="hasError('message')"
              :error-messages="getError('message')"
          />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              @click="send"
          >
            Send Message
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import { eventBus } from '@/main'

export default {
  name: 'LoginSupportComponent',
  data () {
    return {
      dialog: false,
      isLoading: false,
      requestData: {
        email: null,
        phone: null,
        message: null
      }
    }
  },
  computed: {
    ...mapGetters('errors', ['hasError', 'getError'])
  },
  methods: {
    ...mapActions('auth', ['sendSupportMessage']),
    send () {
      this.isLoading = true
      const promise = this.sendSupportMessage(this.requestData)
      promise.then(r => {
        this.isLoading = false
        this.dialog = false
        eventBus.$root.$emit(
          'send_notify',
          'success',
          'Message Sent!',
          'Your support message has been sent successfully'
        )
      }).catch(e => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
