<template>
  <div v-if="user">
    <v-app-bar
      app
      color="#00295a"
      clipped-left
      clipped-right
      dark
      elevation="8"
    >
      <v-app-bar-nav-icon @click="show_aside = !show_aside"></v-app-bar-nav-icon>
      <div
        class="d-flex align-center cursor-pointer"
        @click="$router.push('/dashboard/projects').catch(e => {})"
      >
        <v-app-bar-title></v-app-bar-title>
        <v-img
          alt="Logo"
          class="shrink mr-1 ml-4"
          contain
          src="/images/lsp-logo.png"
          transition="scale-transition"
          width="120"
        />
      </div>
      <v-spacer></v-spacer>
      <v-btn rounded text to="/dashboard/balance">
        <v-icon class="mr-2">mdi-cash</v-icon>
        {{ formatPrice(user.balance / 100) }}
      </v-btn>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            class="mx-2"
            v-bind="attrs"
            v-on="on"
            small
            fab
            depressed
          >
            <v-icon>person</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon
                class="grey lighten-1"
                dark
              >person
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title style="font-size: 16px">
                {{ user.name }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item-group
            color="primary"
          >
            <v-list-item @click="$router.push('/dashboard/profile')">
              <v-list-item-title>
                {{ $t('user.profile') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout()">
              <v-list-item-title>{{ $t('login.out') }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <NotificationsComponent/>
    </v-app-bar>
    <MainMenuAsideComponent :is_show="show_aside"/>
    <ActionAsideComponent
      :is_show="show_action_aside"
      @show-action-menu="e => show_action_aside = e"
    />
    <v-main>
      <transition name="fade-transition" mode="out-in">
        <router-view/>
      </transition>
    </v-main>
    <v-fab-transition>
      <v-btn
        v-if="showNeedActions"
        v-show="!show_action_aside"
        color="black"
        dark
        fixed
        bottom
        right
        fab
        @click="show_action_aside = true"
      >
        <v-badge
          :content="count_actions"
          :value="count_actions"
          color="red"
        >
          <v-icon>mdi-alert</v-icon>
        </v-badge>
      </v-btn>
    </v-fab-transition>
    <v-dialog
      v-model="show_get_start_window"
      max-width="400"
    >
      <v-card>
        <v-toolbar flat>
          <v-icon left>mdi-comment-question-outline</v-icon>
          <v-toolbar-title>Get Started: Integration Guide</v-toolbar-title>
          <v-spacer/>
          <v-btn icon @click="show_get_start_window = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text>
          <div class="mt-4 mb-4">
            This guide provides all the necessary settings for integrating and activating your project with LSP.
            Follow the detailed instructions to ensure a smooth setup process.<br>
            <br>
            If you have any questions or require further assistance, please contact our specialists. We are here to
            support you at every step.
          </div>
          <v-btn block dark color="green" @click="goToHelp()">Go to Integration Guide</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
  <div v-else class="text-center" style="margin-top: 50vh;">
    <v-progress-circular
      :size="50"
      color="green"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
import { translate } from '@/mixins/translate'
import { price } from '@/mixins/price'
import { ws } from '@/mixins/ws_notification'
import { mapActions, mapGetters, mapState } from 'vuex'
import MainMenuAsideComponent from '@/components/asides/MainMenuAsideComponent'
import ActionAsideComponent from '@/components/asides/ActionAsideComponent.vue'
import NotificationsComponent from '@/components/Notifications/NotificationsComponent.vue'

export default {
  name: 'DashboardLayout',
  mixins: [translate, price, ws],
  components: {
    NotificationsComponent,
    ActionAsideComponent,
    MainMenuAsideComponent
  },
  data: () => ({
    menu: true,
    show_aside: true,
    show_action_aside: false,
    show_get_start_window: false
  }),
  computed: {
    ...mapGetters('actions', ['count_actions']),
    ...mapState('auth', ['user']),
    ...mapState('actions', ['actions']),
    showNeedActions: {
      get () {
        if (this.$route.fullPath.includes('/dashboard/chats')) {
          return false
        }

        if (this.$route.fullPath.includes('/dashboard/projects/')) {
          return false
        }

        return true
      }
    }
  },
  methods: {
    ...mapActions('auth', ['getUser', 'logout']),
    ...mapActions('actions', ['getActionList']),
    goToHelp () {
      this.show_get_start_window = false
      this.$router.push('/dashboard/articles/3')
    }
  },
  async created () {
    await this.getUser()
    const actionFilters = {}

    if (this.$route.fullPath.includes('/dashboard/projects/')) {
      actionFilters.project_id = this.$route.params.id
    }

    await this.getActionList(actionFilters)
    this.joinWS()

    this.$root.$on('show_action_request_aside', () => {
      this.show_action_aside = true
    })
  },
  mounted () {
    this.$root.$on('show_get_started', () => {
      this.show_get_start_window = true
    })
  }
}

</script>

<style lang="scss">
</style>
