import axios from 'axios'

const baseURL = process.env.VUE_APP_SERVER_URL

export default {
  namespaced: true,
  state: {
    is_load: false,
    transaction: null,
    transactions: [],
    pagination: {
      current_page: 1,
      per_page: localStorage.getItem('helix_per_page') || 10
    },
    filters: {
      number: null,
      status_id: null,
      project_id: null
    }
  },
  mutations: {
    setTransaction (state, payload) {
      state.transaction = payload
    },
    setTransactions (state, payload) {
      state.transactions = payload
    },
    setPagination (state, payload) {
      state.pagination = payload
    },
    setPage (state, value) {
      state.pagination.current_page = value
      if (value > state.pagination.last_page) {
        state.pagination.current_page = state.pagination.last_page
      }
      if (value < 1) {
        state.pagination.current_page = 1
      }
    },
    setPerPage (state, value) {
      state.pagination.per_page = value
      localStorage.setItem('helix_per_page', value)
    },
    setFilterStatusId (state, value) {
      state.filters.status_id = parseInt(value) || null
    },
    setFilterProjectId (state, value) {
      state.filters.project_id = value || null
    },
    setSearchNumber (state, value) {
      state.filters.number = value
    }
  },
  actions: {
    async getTransactions ({ state, commit }) {
      state.is_load = true
      const promise = axios.get(baseURL + '/api/transactions', {
        params: {
          page: state.pagination.current_page || 1,
          per_page: state.pagination.per_page || 10,
          status_id: state.filters.status_id,
          project_id: state.filters.project_id,
          number: state.filters.number
        }
      })
      promise.then(response => {
        commit('setTransactions', response.data.data)
        commit('setPagination', response.data.meta)
        state.is_load = false
      }).catch(e => {
        state.is_load = false
      })

      return promise
    },
    async getTransaction ({ state, commit }, id) {
      return axios.get(baseURL + '/api/transactions/' + id)
    },
    async createTransaction ({ state, commit }, data) {
      state.is_load = true
      const promise = axios.post(baseURL + '/api/transactions', data)
      promise.then(response => {
        commit('setTransaction', response.data.data)
        state.is_load = false
      }).catch(e => {
        state.is_load = false
      })

      return promise
    }
  }
}
