<template>
  <v-app v-if="layout">
    <component :is="layout">
      <router-view/>
    </component>
    <notifications
      position="bottom right"
      class="notify"
      group="notify"
    />
  </v-app>
</template>

<script>

import { eventBus } from '@/main'

export default {
  name: 'App',
  data: () => ({
    show_aside: true
  }),
  computed: {
    layout () {
      const prefix = this.$vuetify.breakpoint.mobile ? '-mobile' : ''
      const l = this.$route.meta.layout || null

      return l ? l + prefix : null
    }
  },
  methods: {
  },
  created () {
  },
  mounted () {
    eventBus.$on('send_notify', (type, title, message) => {
      this.$notify({
        type: type,
        group: 'notify',
        title: title,
        text: message,
        duration: 5000
      })
    })
  },
  beforeDestroy () {
    eventBus.$off('send_notify')
  }
}
</script>

<style lang="scss">
@import "assets/scss/helix-style";
</style>
